import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import prev from '../img/hombre.webp'
import prevet from '../img/prevendient.webp'
import circulo from '../img/circulo.webp'
function Odontologiaprev() {
    return (
        <>
            <div>
            <Container className='container-no-format'>
                <div>
                    <div><img loading="lazy"  alt="" src={prev } className='image-100'/></div>
                </div>        
            </Container> 
            <Container className='container-default'>
            <Row>
                        <Col><div className="banner-title-bg">Odontología preventiva</div>
                        </Col>
            </Row>
            <br/>
            <Row>
                <div align="center">
                    <div className="banner-descrip-orto">
                    Empleamos métodos, técnicas y tecnología especial para
                    proporcionar un diagnóstico acertado y confiable.
                    </div>
                </div>
            <div className="clearfixe-body"></div>
            </Row>
                </Container>
                <div>
                    <Container>
                        <Row className='align-items-center'>
                            <Col md={ 5 }>
                                <div className='font-blond-small'>
                                A través del diagnóstico podemos
                                    identificar:<br />
                                </div>
                                <div>
                                    <div className="clearfixe-body"></div>
                                    
                                    <div><img loading="lazy"  alt="" src={circulo } className='circle'/>&nbsp;&nbsp;Caries ocultas. </div><br/>
                                    <div><img loading="lazy"  alt="" src={circulo } className='circle'/>&nbsp;&nbsp;Grietas, fracturas o perforaciones dentales. </div><br/>
                                    <div><img loading="lazy"  alt="" src={circulo } className='circle'/>&nbsp;&nbsp;Gingivitis.</div><br/>
                                    <div><img loading="lazy"  alt="" src={circulo } className='circle'/>&nbsp;&nbsp;Malposiciones dentarias.</div><br/>
                                    <div><img loading="lazy"  alt="" src={circulo } className='circle'/>&nbsp;&nbsp;Enfermedades de la encía o cáncer oral.</div><br/>
                                    <div><img loading="lazy"  alt="" src={circulo } className='circle'/>&nbsp;&nbsp;Pérdida de hueso.</div><br/>
                                </div>
                            </Col>
                            <Col md={ 7 }>
                                <div><img loading="lazy"  alt="" src={prevet} className='img-alin'/></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );

}
export default Odontologiaprev;