import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import paciente from '../img/paciente.webp'
import carilla from '../img/carillas.webp'
import resinas from '../img/resinas.webp'
import blanqueo from '../img/blanqueamiento_maq.webp'
import incrusta from '../img/incrustaciones.webp'
import bondings from '../img/bondings.webp'
function Estetica() { 
    return (
        <>
          <div>
            <Container className='container-no-format'>
                    <div>
                        <div><img loading="lazy"  alt="" src={paciente } className='image-100'/></div>
                    </div>        
                </Container> 
                
                <Container className='container-default'>
                <Row>
                            <Col><div className="banner-title-bg">Estética dental</div>
                            </Col>
                </Row>
                <br/>
                <Row>
                    <div align="center">
                        <div className="banner-descrip-orto">
                        El objetivo es mantener la belleza natural de los dientes y conseguir una sonrisa más
                        bonita y armónica, mediante tratamientos sencillos, indoloros y de corta duración.
                        </div>
                    </div>
                    <div className="clearfixe-body"></div>
                </Row>
                </Container>
                <div>
                    <Container>
                        <Row>
                            <Col md={ 4 }>
                                <Row ><div className='format-estetica-title'>Tratamos:</div></Row>
                                <div className="clearfixe-body"></div>
                                    <samp className='font-blond-small'>• Diastemas:</samp> <span className='font-desc'>Separación entre
                                    los dientes de enfrente.</span><br/>

                                    <samp className='font-blond-small'>• Dientes apiñados o disparejos</samp>
                                    <span className='font-desc'> que generan un desagradable
                                    apariencia.</span><br/>

                                    <samp className='font-blond-small'>• Dientes manchados y/o
                                    pigmentados.<br/></samp>

                                    <samp className='font-blond-small'>• Acumulación de sarro.<br/></samp>

                                    <samp className='font-blond-small'> • Caries cercanas a la encía.<br/></samp>

                                    <samp className='font-blond-small'>• Desgaste de dientes</samp> 
                                    <span className='font-desc'> debidos a
                                    fallas en la mordida, o por estrés
                                    (bruxismo).</span><br/>

                                    <samp className='font-blond-small'>• Restauraciones desajustadas.<br/></samp>

                                    <samp className='font-blond-small'>• Restauraciones antiguas</samp> 
                                    <span className='font-desc'> de amalgama o de materiales no estéticos
                                    que no semejan el color ni la estructura.</span><br/>
                            </Col>
                            <Col md={ 4 } align="center">
                                <div className='p-3'><img loading="lazy"  alt="" src={resinas} className='img-alin' /></div>
                                <div className="clearfixe-body"></div>

                                <div className='font-blond-small'>Resinas dentales</div>
                                <div className='font-desc'>
                                    Las resinas dentales se utilizan para tratar
                                dientes con caries, una alternativa
                                estética que se utiliza en lugar de las
                                amalgamas.</div>
                            </Col>

                            <Col md={ 4 } align="center">
                            <div className='p-3'><img loading="lazy"  alt="" src={carilla} className='img-alin' /></div>
                            <div className="clearfixe-body"></div>

                            <div className='font-blond-small'>Carillas de porcelana</div>
                            <div className='font-desc'>
                            Las carillas de porcelana nos ayudan a
                            cambiar la forma, color o posición de los
                            dientes.
                            </div>
                            </Col>
                            

                        </Row>
                        <div className="clearfixe-body"></div>
                        <Row>
                        <Col md={ 4 } align="center">
                            
                            <div className='p-3'><img loading="lazy"  alt="" src={blanqueo} className='img-alin' /></div>
                            <div className="clearfixe-body"></div>

                            <samp className='font-blond-small'>Blanqueamiento</samp>
                            <div className='font-desc'>
                            Más de dos décadas brindando un brillo
                            natural a tus dientes utilizando
                            herramientas y materiales de punta.
                            </div>
                            </Col>
                        <Col md={ 4 } align="center">
                            <div className='p-3'><img loading="lazy"  alt="" src={incrusta} className='img-alin' /></div>
                            <div className="clearfixe-body"></div>

                            <samp className='font-blond-small'>Coronas</samp>
                            <div className='font-desc'>
                            Tratamiento estético para reconstruir
                            dientes posteriores destruidos con
                            cavidades medianas o grandes.
                            </div>
                        </Col>
                        <Col md={ 4 } align="center">
                            <div className='p-3'><img loading="lazy"  alt="" src={bondings} className='img-alin' /></div>
                            <div className="clearfixe-body"></div>
                            
                            <samp className='font-blond-small'>Bondings</samp>
                            <div className='font-desc'>
                                Son resinas estéticas que permiten igualar la forma, color, tamaño de los dientes anteriores. Con este sencillo procedimiento se puede lograr una gran estética dental.
                            </div>
                        </Col>

                        </Row>
                    </Container>
                </div>
                <div className="clearfixe-body"></div>
            </div>
        </>
    );


}
export default Estetica;

