import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import { articlesList } from '../json/articles'

function Blog() {
    return (
        <>
            <div>
      <Container className='container-default'>
          <div className='banner-title-bg'>Blog</div>
          <div className="clearfixe-body"></div>
          <div>
            <Row>
              {articlesList.map( item => (
                <Col md={ 4 } xs={ 12 } className='col-md-custom article-item'>
                <div className="card" >
                  <img loading="lazy" src={ item.img } className="card-img-top" alt="..."/>
                  <div className="card-body">
                  <h5 className="card-title">{ item.title }</h5>
                  <p className="card-text">{ item.desc }</p>
                  <a href={ item.url } className='btn btn-primary more-text'>Leer más</a>
                  </div>
                </div>    
                </Col>
              ))}
            </Row>
          </div>
          <div className="clearfixe-body"></div>
          <div align='center'>
          </div>
      </Container>
    </div>

        </>
    );

}
export default Blog;

