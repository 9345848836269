import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import doc from '../img/doc.webp'
import cora from '../img/corazon.webp'
import manos from '../img/manos.webp'
import mundo from '../img/mundo.webp'
function Nosotros()
{ 
    return (
        <>
            <div className="banner-bg-ques" align="center">
        <Container>
          <Row className='align-items-center'>
            
            <Col md={5}>
              <div className="banner-container-ques" align="center">
                <div className="banner-text-container">
                  <div className="banner-title-ques">¿Qué es</div>
                  <div className="banner-title-ques-2">D-Natural?</div>
                  <div className="banner-desc-ques">
                    D-Natural está integrado por un increíble equipo de trabajo, <span className='video'>que ama y disfruta lo que hace.</span> Se prepara continuamente desarrollando sus habilidades a través de capacitación continua, incluyendo nueva tecnología en su práctica diaria,
                    para que te sientas <span className='video'>orgulloso de tu sonrisa.</span>
                    <br/>
                    {/* <div>
                      <a href="" className="oblique-button">
                        Conocer más
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col className='banner-bg-ques' md={7} align='center'>
              <img loading="lazy"  alt="" src={doc} className='doc-style' />
            </Col>
          </Row>

          <Row>
            <Container >
              <Row>
                <Col md={ 4 } xs={ 12 }  className='col-md-custom-comp'>
                  <div className='comp-title' style={{ textAlign: 'left' }}>¿Qué hacemos?</div>
                  <div className='font-desc' style={{ textAlign: 'left' }}>
                    <ul>
                      <li>
                        Transformamos vidas a través de la sonrisa.
                      </li>
                      <li>
                        Hemos creado sonrisas durante 30 años.
                      </li>
                      <li>
                        Construimos tu seguridad y confianza disfrutando de una sonrisa saludable.
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={ 4 } xs={ 12 }  className='col-md-custom-comp'>
                  <div className='comp-title' style={{ textAlign: 'left' }}>¿Cómo lo hacemos?</div>
                  <div className='font-desc' style={{ textAlign: 'left' }}>
                    <ul>
                      <li>
                        Utilizamos tecnología de punta.
                      </li>
                      <li>
                        Equipo radiológico.
                      </li>
                      <li>
                        Tecnología 3D.
                      </li>
                      <li>
                        Sistema de limpieza dental SIN DOLOR.
                      </li>
                      <li>
                        Materiales dentales de calidad.
                      </li>
                      <li>
                        Amplia experiencia.
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={4} xs={12} className='col-md-custom-comp'>
                  <div className='comp-title' style={{ textAlign: 'left' }}>¿Cómo será tu experiencia?</div>
                  <div className='font-desc' style={{ textAlign: 'left' }}>
                    <ul>
                      <li>
                        Escucharemos tus necesidades y deseos para ayudarte a crear la sonrisa que siempre has deseado.
                      </li>
                      <li>
                        Te brindaremos un trato de calidez, dando lo mejor de nosotros mismos, consintiéndote, respetándote y entendiéndote.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>        
            </Container>
          </Row>
        </Container>
            </div>
        <div className='comp-background'>
            <Container >
                    <Row>
                        <Col md={ 4 } xs={ 12 }  className='col-md-custom-comp'>
                            <div className='comp-title'><img loading="lazy"  alt="" src={mundo} className='comp-img'></img></div>
                            <div className='comp-title'>Nuestros compromisos</div>
                            <div className='comp-conte'>Ayudarte a sentirte bien contigo mismo.
                                Trabajar con equipo, tecnología y
                                materiales de calidad.
                                Brindarte eficiencia y calidad en nuestro
                                trabajo.</div>
                        </Col>
                        <Col md={ 4 } xs={ 12 }  className='col-md-custom-comp'>
                            <div className='comp-title'><img loading="lazy"  alt="" src={manos} className='comp-img'></img></div>
                            <div className='comp-title'>Compromiso social</div>
                            <div className='comp-conte'>En D-Natural colaboramos con
                            Comedores de Amor AC, llevando
                            atención y servicios dentales gratuitos a
                            niños y jóvenes desprotegidos que viven
                            en zonas marginadas.
                            </div>
                        </Col>
                        <Col md={4} xs={12} className='col-md-custom-comp'>
                            <div className='comp-title'><img loading="lazy"  alt="" src={cora} className='comp-img'></img></div>
                        <div className='comp-title'>Otras acciones</div>
                            <div className='comp-conte'>Pláticas y revisiones de salud dental en
                                Instituciones Educativas.<br/><br/>
                                Campañas de salud dental en
                                corporativos.
                            </div>
                        
                        </Col>
                    </Row>        
                    { isMobile && <br/> }  
            </Container>
        </div>     
        </>
    );
}
export default Nosotros;