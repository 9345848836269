import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import airflowb from '../img/airflowb.webp'
import airflowImg from '../img/airflow-img.webp'

import perioflow from '../img/perioflow.webp'
import revelar from '../img/revelar.webp'
import valores from '../img/valores.webp'
import motivar from '../img/motivar.webp'
import cita from '../img/cita.webp'
import controlar from '../img/controlar.webp'
import piezon from '../img/piezon.webp'
import airflowc from '../img/airflowc.webp'

const steps = [
    {
        id: 1,
        title: 'Valorar',
        desc: 'Estudiar y valorar cada caso clínico.',
        img: valores
    },{
        id: 2,
        title: 'Revelar',
        desc: 'Hacer el biofilm visible: se muestra a los pacientes el biofilm revelado y sus zonas problemáticas.',
        img: revelar
    },{
        id: 3,
        title: 'Motivar',
        desc: 'Concientizar e instruir para promover la prevención y la higiene bucal.',
        img: motivar
    },{
        id: 4,
        title: 'Natural Flow®',
        desc: 'Eliminar el biofilm, las manchas y el cálculo.',
        img: airflowc
    },{
        id: 5,
        title: 'Perio Flow®',
        desc: 'Eliminar el biofilm en las bolsas de las encías.',
        img: perioflow
    },{
        id: 6,
        title: 'Piezon® PS',
        desc: 'Eliminar los depósitos duros restantes. Se trata de una eliminación selectiva, indolora y suave del sarro.',
        img: piezon
    },{
        id: 7,
        title: 'Controlar',
        desc: 'Comprobar, de forma exhaustiva, si se ha eliminado todo el biofilm. Para asegurar que todos los dientes y los tejidos blandos estén en buen estado.',
        img: controlar
    },{
        id: 8,
        title: 'Nueva cita',
        desc: 'Programar cita para seguimiento.',
        img: cita
    },
]

function Ortodoncia()
{
    return (
        <>
            
            <div>
                <Container className='container-no-format'> 
                    <div>
                        <img loading="lazy" alt="" src={ airflowb } className='image-100'></img>
                    </div>
                </Container>

            </div>
            <div className='container-default' style={{ paddingBottom: 0 }}>
                <Container>
                    <Row>
                            <Col>
                                <div className="banner-title-bg">Limpieza de última generación</div>
                                <div className="banner-title-small">Natural Flow</div>
                            </Col>
                    </Row>
                    <br/>
                    <Row>
                        <div align="center">
                            <div className="banner-descrip-orto">
                            ¡La mejor limpieza dental de la historia!
                            </div>
                        </div>
                    </Row>
                    <Row>
                            <Col md={ 6 } align='left'>
                                <div>
                                    { !isMobile &&
                                        <>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </>
                                    }
                                    <br/>
                                    <div className='font-desc-regular'>
                                        <div>Natural Flow es una innovación suiza que transformará totalmente tu experiencia en el consultorio dental al cambiar los métodos de limpieza tradicionales por un sistema revolucionario que ofrece una vida de cuidado de la salud bucal mínimamente invasiva, lo que lo hace ideal para todas las edades. </div>
                                        <br/>
                                        <div>Natural Flow elimina el biofilm -también llamado placa bacteriana o sarro-, agrupaciones bacterianas que se adhieren a superficies de los dientes, implantes y tejidos blandos.  </div>
                                        <br/>
                                        <div>Si no se trata a tiempo, el biofilm puede originar caries, halitosis, pérdida de dientes, sangrado de encías (gingivitis), inflamación y retracción de encías (periodontitis); también aumenta la posibilidad de padecer Alzhéimer, diabetes, cardiopatías, artritis y enfermedades en las vías respiratorias.</div>
                                    </div>
                                </div>
                                <br/>
                                <div className='banner-bg-orto p-ortodoncia font-orto' align="center">
                                    <br/>
                                    <div className='font-blond' style={{ textAlign: 'center' }}>¿Por qué es la mejor opción?</div>
                                    
                                    <div className='font-desc' style={{ textAlign: 'center' }}>
                                        <br/>
                                        <div>Natural Flow es altamente efectivo, mínimamente invasivo y suave, lo que significa que las personas con enfermedad de las encías, aparatos ortopédicos, coronas, puentes, carillas e implantes son elegibles para el tratamiento. </div>
                                        <br/>
                                        <div>Estos procedimientos son tan suaves que se pueden usar en dientes naturales, restauraciones, aparatos de ortodoncia, implantes, la lengua y las encías.</div>
                                        <br/>
                                        <div>Los ultrasonidos convencionales y los instrumentos rotatorios pueden dañan los dientes y las encías. Con los métodos tradicionales, que se basan en el uso de cepillos, pasta de pulir e instrumentos manuales, únicamente se elimina el 50% del biofilm en áreas de difícil acceso, por lo que la infección sigue progresando. </div>
                                        <br/>
                                        <div>Además, con frecuencia el paciente no recibe instrucciones para la higiene bucodental en casa. </div>
                                    </div>
                                </div>
                            </Col>        
                            <Col md={ 6 } align='center' >
                                <Row>
                                    <div className='padding-img' >
                                        <img loading="lazy" alt="" src={airflowImg} className='banner-img' />
                                    </div>

                                    <div className='font-blond' > ¿Cuáles son los beneficios de Natural Flow? </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div className='font-desc' style={{ textAlign: 'left', paddingLeft: '50px' }}>
                                        <ul>
                                            <li>Evita y trata la gingivitis</li>
                                            <li>Ayuda a la detección de caries</li>
                                            <li>Ayuda a tratar la perimplantitis de forma mínimamente invasiva</li>
                                            <li>Motiva a los niños a prevenir la caries</li>
                                            <li>Elimina eficazmente el cálculo en las zonas de difícil acceso</li>
                                            <li>Evita la caries durante el tratamiento de ortodoncia</li>
                                            <li>Mantiene en buen estado los aparatos de ortodoncia</li>
                                            <li>Preserva la dentina expuesta, previniendo la sensibilidad dental</li>
                                            <li>Elimina las manchas en las piezas dentales</li>
                                        </ul>
                                    </div>
                                </Row>
                            </Col>
                    </Row>    
                </Container>
                <br/><br/>
                <div className='container-default comp-background'>
                    <Container>
                        <div>
                        <Row className='align-items-center'>
                            <Col md={ 5 } >
                                <div className='teeth-back'>
                                    <div className='font-blond' > ¿Cuáles son los beneficios de Natural Flow? </div><br/>

                                    <div className='font-desc-regular'>
                                        La tecnología Natural Flow combina agua tibia, aire y polvo suave para eliminar todas las bacterias (biofilm) en la boca, que contribuyen a la caries dental y la enfermedad de las encías.  
                                    </div>
                                </div>
                            </Col>
                            <Col md={ 7 } >
                                <Container>
                                    { steps.map( item => (
                                        <Row className='align-items-center pb-3'>
                                            <Col md={ 'auto' }>
                                                <div className={ `air-item air-item-${ item.id }` }><img loading="lazy" alt="" src={ item.img } width={ '80px' }/></div>
                                            </Col>
                                            <Col>
                                                <div className={ `air-item air-item-${ item.id }` }>
                                                    <div className='font-blond-small' style={{ textTransform: 'uppercase' }}>
                                                        <span className='circle'>{ item.id }</span>&nbsp;&nbsp;&nbsp; <span>{ item.title }</span>
                                                    </div>
                                                    <div className='font-desc'>
                                                        { item.desc }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ) )}
                                </Container>
                            </Col>
                        </Row>
                        </div>
                    </Container>
                </div>

            </div>
        </>
    );
}
export default Ortodoncia;