import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
//import { useHover } from "@uidotdev/usehooks";
import { isMobile } from 'react-device-detect';

import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import blanque from '../img/blanqueamiento.webp'
import blanque_b from '../img/blanqueamiento_b.webp'
import blanqdnat from '../img/blanqdnatural.webp'
import blanqxpres from '../img/blanqxpress.webp'
import blanqguar from '../img/blanqguardas.webp'
import blanqnv from '../img/blanqnv.webp'

function Blanqueamiento() {
    //const [ref, hovering] = useHover();
    
    return (
        <>
            <div>
                {/* <Container className='container-no-format'> 
                    <Row ref={ ref }>
                        { hovering ? <img loading="lazy"  alt="" src={ blanque_b }></img> : <img loading="lazy"  alt="" src={ blanque }></img> }
                    </Row>
                </Container>*/}
            {<Container className='container-no-format'>
                <div>
                    <ReactCompareSlider style={{ width: '100%', height: 'auto' }} position={ 50 } portrait={ false }
                        itemOne={<ReactCompareSliderImage src={blanque} />}
                        itemTwo={<ReactCompareSliderImage src={blanque_b} />}
                    />
                </div>        
            </Container>}
         
                <Container className='container-default'>
                    <Row>
                            <Col><div className={ isMobile ? 'banner-title-bg-mobile' : "banner-title-bg" }>Blanqueamiento dental</div>
                            </Col>
                    </Row>
                    <br/>
                    <Row>
                        <div align="center">
                            <div className="banner-descrip-orto">
                                Le brindamos un brillo natural a tus dientes con el sistema ZOOM 2. Reconocido por ser más rápido,
                                efectivo y seguro
                            </div>
                        </div>
                    </Row>
                </Container>
                <div className="clearfixe-body"></div>
                <div>
                    <Container>
                        <Row className='font-orto font-aling-center'>
                            <Col md={3}>
                                <Row><div><img loading="lazy" alt="" src={blanqdnat} className='img-alin' /></div>
                                
                                </Row>
                            <Row>
                                <div> 
                                    <div className="clearfixe-body"></div>
                                    <span className='font-blond-small'>Blanqueamiento
                                        D-Natural </span><br/>
                                    <div className='font-desc'>
                                        Tratamiento completo que
                                        combina el blanqueamiento
                                        xpress y el de guardas, logrando
                                        reducir la duración del
                                        tratamiento y consiguiendo
                                        excelentes resultados.
                                    </div>
                                </div>
                            </Row>
                            </Col>
                            <Col md={3}>
                            <Row><div><img loading="lazy" alt="" src={blanqxpres} className='img-alin'/></div>
                                
                                </Row>
                            <Row>
                                <div className="clearfixe-body"></div>
                                <div> <span className='font-blond-small'>Blanqueamiento Xpress<br/></span>
                                    
                                    <div className='font-desc'>
                                    Sistema a base de luz de alto
                                    espectro en combinación con
                                    un gel activador. Verás
                                    resultados desde la primera
                                    sesión, en tan sólo 1 hora.
                                    </div>
                                </div>
                            </Row>
                            </Col>
                            <Col md={3}>
                            <Row><div><img loading="lazy" alt="" src={blanqguar} className='img-alin' /></div>
                                
                                </Row>
                            <Row>
                                <div className="clearfixe-body"></div>
                                <div><span className='font-blond-small'>Blanqueamiento Guardas<br/></span>
                                    <div className='font-desc'>
                                    Confeccionamos unas guardas
                                    en las que aplicará un gel de
                                    aclaramiento. Deberá usarlas
                                    en casa por 3 horas diarias,
                                    durante 2 a 3 semanas.
                                    </div>
                                </div>
                            </Row>
                            </Col>
                            <Col md={3}>
                            <Row><div><img loading="lazy" alt="" src={blanqnv} className='img-alin' /></div>
                                
                                </Row>
                            <Row>
                                <div className="clearfixe-body"></div>
                                    <div><span className='font-blond-small'>Blanqueamiento Dientes
                                        no Vitales <br/></span>
                                        
                                        <div className='font-desc'>
                                        A través de este sencillo
                                        procedimiento podemos
                                        aclarar los dientes tratados por
                                        endodoncia para que luzcan
                                        como los demás.
                                        </div>
                                </div>
                            </Row>
                            </Col>
                        </Row>
                    </Container>

                </div>

            </div>
            <div className="clearfixe-body"></div>
        
        </>

    );
}
export default Blanqueamiento;