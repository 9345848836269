import { useState, useEffect } from 'react';
import { articlesList } from '../json/articles'

import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const path = window.location.pathname;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

function Articulo() {
  const [, setNumPages] = useState(null);
  const [pageNumber, ] = useState(1);
  const [ pdfWidth, setPdfWidth ] = useState(0);
  const [ articleSelected, setArticleSelected ] = useState(null)

  useEffect(() => {
    console.log("path", path)
    var w = document.getElementById('pdfCanvas').offsetWidth;
    var pdfFound = articlesList.find(x => x.url === path);

    if( pdfFound ) setArticleSelected(pdfFound)

    setPdfWidth( w - 50 )
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

    return (
        <>
            <div>
      <Container className='container-default'>
        <Row>
          <Col md={ 8 } id='pdfCanvas'>
            <Document file={ articleSelected != null ? articleSelected.pdf : '' } onLoadSuccess={onDocumentLoadSuccess} options={ options }>
              <Page width={ pdfWidth } height={ 'auto' } pageNumber={ pageNumber } renderTextLayer={ false }/>
            </Document>
          </Col>
          <Col md={ 4 }>
            <div className='equipo-trabajo-main' style={{ textAlign: "left", padding: 0, color: '#364363' }}>CATEGORÍAS</div>
            <br/>
            <ul className='one'>
              { articlesList.slice(0, 4).map( item => (
                <li className={ (articleSelected != null && articleSelected.title === item.title) ? "li-blog-selected" : "li-blog" } onClick={ () => window.location.href = item.url }>
                  <div className={ (articleSelected != null && articleSelected.title === item.title) ? 'blog-list blog-list-selected' : 'blog-list' }>{ item.title }</div>
                </li>
              ))}
            </ul>

          </Col>
        </Row>
      </Container>
    </div>

        </>
    );

}
export default Articulo;