import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import cirugia from '../img/cirugiaprin.webp'
import tercmol from '../img/tercerosmol.webp'
import creci from '../img/crecimiento.webp'
import periodent from '../img/periodont.webp'
import implante from '../img/implantescir.webp'
import alarga from '../img/alargamientocir.webp'
function Cirugia() {
    return (
        <>
            <div>
            <Container className='container-no-format'>
                    <div>
                        <div><img loading="lazy"  alt="" src={cirugia } className='image-100'/></div>
                    </div>        
                </Container> 
                <Container>
                <div className="clearfixe-body"></div>
                <Row>
                            <Col><div className="banner-title-bg">Cirugía</div>
                            </Col>
                </Row>
                <div className="clearfixe-body"></div>
                    <Row>
                    <div align="center">
                        <div className="banner-descrip-orto">
                        Patologías que se tratan con cirugía
                        </div>
                    </div>
                <div className="clearfixe-body"></div>
                </Row>
                </Container>
                <div>
                    <Row className='justify-content-center'>
                    
                        <Col md={3}>
                        <Row className='align-items-center'>
                               <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={tercmol} className='padding-img img-alin'/>    </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Terceros molares</span><br />
                                <div className='font-desc'>       
                                92 % de los pacientes necesitan quitarse
                                los terceros molares por diversos factores.
                                La cirugía reduce el traumatismo
                                quirúrgico.

                                </div>
                            </div>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Row className='align-items-center'>
                               <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={creci} className='padding-img img-alin'/>    </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Crecimiento asimétrico de la mandíbula</span><br />
                                <div className='font-desc'>       
                                Este padecimiento en los pacientes 
                                causa problemas funcionales y
                                dificultades para masticar, hablar,
                                tendencias a respirar por la boca.

                                </div>
                            </div>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Row className='align-items-center'>
                               <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={alarga} className='padding-img img-alin'/>    </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Enfermedad Periodontal</span><br />
                                <div className='font-desc'>       
                                Restauramos el tejido periodontal que
                                se haya perdido y brindamos resultados
                                favorables.     

                                </div>
                            </div>
                            </Row>
                            </Col>
                        
                    </Row>
                    <Row>
                        <Col md={3}></Col>
                    <Col md={3}>
                            <Row className='align-items-center'>
                               <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={implante} className='padding-img img-alin'/>    </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Implantes / Injerto de Hueso</span><br />
                                <div className='font-desc'>       
                                Cuando existe una destrucción o pérdida
                                considerable de hueso, podemos realizar
                                un injerto para soportar un implante.
                                </div>
                            </div>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Row className='align-items-center'>
                               <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={periodent} className='padding-img img-alin'/>    </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Alargamiento de corona</span><br />
                                <div className='font-desc'>       
                                Tratamiento de uno o varios dientes a
                                    los que se les va hacer una corona y
                                    cuando queremos eliminar bolsas
                                    periodontales. 
                            </div>
                        </div>
                            </Row>
                        </Col>
                        <Col md={3}></Col>
                    </Row>

                </div>
                <div className="clearfixe-body"></div>

                </div>
        
        </>
    );
}
export default Cirugia;