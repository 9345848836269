import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import prot from '../img/protesis.webp'
import protrem from '../img/protesisremo.webp'
import protefi from '../img/protesisfija.webp'
import protetotal from '../img/protesistotal.webp'

function Protesis() {
    return (
        <>
             <div>
            <Container className='container-no-format'>
                    <div>
                        <div><img loading="lazy"  alt="" src={prot} className='image-100'/></div>
                    </div>        
                </Container> 
                <Container className='container-default'>
                    
                <Row>
                            <Col><div className="banner-title-bg">Prótesis</div>
                            </Col>
                </Row>
                <div className="clearfixe-body"></div>
                    <Row>
                        <div align="center">
                        <div className="banner-descrip-orto">
                        Una prótesis dental es un elemento artificial destinado a restaurar la anatomía
                        de una o varias piezas dentales, devolviendo a la boca la estética y la función.
                            </div>
                        </div>
                
                </Row>
                </Container>
                
                <Container>
                    <Row>
                        <Col md={4}>
                            <Row className='align-items-center'> 
                            <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={protrem} className='padding-img img-alin'/>   </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                <span className='font-blond-small'>Prótesis fijas</span>
                                <div className='font-desc'>     
                                    Cuando hay pérdida de dientes, es necesario sustituirlos por una prótesis. Hay pacientes que a diferencia de una prótesis removible, prefieren una prótesis fija, con el fin de evitar estar quitando y poniendo.
                                </div>
                            </div>
                            </Row>
                        </Col>

                        <Col md={4}>
                            <Row className='align-items-center'>
                               <div  className='padding-img img-alin'> <img loading="lazy"  alt="" src={protefi} className='padding-img img-alin'/>    </div>
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Prótesis removibles</span><br />
                                <div className='font-desc'>       
                                    Cuando hay pérdida de dientes, es necesario sustituirlos por una prótesis. Utilizamos técnicas y materiales biocompatibles ideales para hacer que sientas una mordida estable y confortable. La prótesis removible permite al paciente quitarse o ponerse la prótesis cuando sea necesario.  
                                </div>
                            </div>
                            </Row>
                        </Col>
                        
                        <Col md={4}>
                            <Row className='align-items-center'>
                                <div  className='padding-img img-alin'><img loading="lazy"  alt="" src={protetotal} className='padding-img img-alin'/> </div>   
                            </Row>
                            <Row className='align-items-center'>
                            <div className='font-orto' style={{ textAlign: 'center' }}>
                                    <span className='font-blond-small'>Dentaduras totales</span>
                                    <div className='font-desc'>
                                        Desafortunadamente cuando se pierden los dientes ya sea en uno o en ambos maxilares, se recurre a un tratamiento de prótesis total, en D-Natural contamos con los materiales biocompatibles ideales para lograr que te sientas lo más natural y cómodo posible.
                                    </div>
                            </div>
  
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div className="clearfixe-body"></div>
                <div className='format-footer-endodo p-4'>
                    <div style={{ textAlign: 'center' }}>
                        <Container>
                        <Row className='align-items-center'>
                        En D-Natural, nuestro interés y compromiso es hacerlo con los mejores materiales
                        existentes en el mercado, a través de la experiencia y habilidad de nuestro
                        equipo de especialistas.
                        </Row>
                        </Container>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Protesis;