import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { TelephoneFill, Whatsapp } from 'react-bootstrap-icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'

import receop from '../img/receop.webp'

const API_PATH = 'https://mgpublicidad.mx/dnaturalMailer.php';
const SITE_KEY = "6Lc2T7gnAAAAAIifaowZheaLqm4ytGHtFfWZUxx-";

function Contacto() {
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data === "FAIL" || response.data === "ERROR") {
        setLoading(false)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        window.location.href = '/thanks'
        /*setLoading(false)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
        setShow(false);*/
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

    return (
        <>

      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

             <div className='container-default'>
        <Container>
          <Tabs defaultActiveKey="satelite" id="uncontrolled-tab-example" className="justify-content-center" >
            <Tab eventKey="satelite" title="Satélite" >
              <Container>
                <Row className="align-items-center">
                  <Col md={ 5 }>
                    { isMobile && <br/> }
                    <div className='text-gust'>¡Te estamos <span className='banner-title-address-2'>esperando! </span></div>
                    <br/>
                    <div className='text-address'>Cto. Científicos #28 1er piso<br/>
                      Cd Satélite C.P. 53100 <br/>
                      Naucalpan, Edo. Mex.
                    </div>
                    <br/>

                    <div>
                      <div className='phones-contact-text'>
                        <TelephoneFill className='phones-contact' ></TelephoneFill>&nbsp;&nbsp; <a href='tel:5555722119' style={{ color: '#000' }}> (55) 5572 2119 / 20 </a>
                      </div>

                      <div className='phones-contact-text'>
                        <Whatsapp className='phones-contact'></Whatsapp>&nbsp;&nbsp; <a href='https://wa.me/5215561728112' style={{ color: '#000' }}> (55) 6172 8112 </a>
                      </div>
                    </div>

                    <br/>
                    <div style={{color:'#00a6e7'}}className='phones-contact-text-2'>
                      <b>HORARIO</b><br/>
                      Lunes a viernes 9 am - 8 pm <br/>
                      Sábados 9 am - 2 pm
                    </div>
                  </Col>

                  <Col md={ 7 }>
                  <div align="center">
                    <div className="clearfixe-body"></div>
                      <iframe title="Ubicación Satélite" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d887.3917804126144!2d-99.22963987022416!3d19.514326386185953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sCto.%20Cient%C3%ADficos%20%2328%201er%20piso%20Cd%20Sat%C3%A9lite%20C.P.%2053100%20Naucalpan%2C%20Edo.%20Mex.!5e0!3m2!1ses-419!2smx!4v1685497840591!5m2!1ses-419!2smx"
                        width={ isMobile ? '100%' : "600" } height={ isMobile ? '250' : "450" } allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="anzures" title="Anzures">
              <Container>
                <Row className="align-items-center">
                  <Col md={ 5 }>
                    { isMobile && <br/> }
                    <div className='text-gust'>¡Te estamos <span className='banner-title-address-2'>esperando! </span></div>
                    <br/>
                    <div className='text-address'>
                      Gauss #12 6to piso<br/>
                      Col. Anzures C.P. 11590<br/>
                      CDMX
                    </div>
                    <br/>

                    <div>
                      <div className='phones-contact-text'>
                        <TelephoneFill className='phones-contact' ></TelephoneFill>&nbsp;&nbsp; <a href='tel:5552500850' style={{ color: '#000' }}>(55) 5250 0850</a>
                      </div>

                      <div className='phones-contact-text'>
                        <Whatsapp className='phones-contact'></Whatsapp>&nbsp;&nbsp; <a href="https://wa.me/5215544990591" style={{ color: '#000' }}>(55) 4499 0591</a>
                      </div>
                    </div>

                    <br/>
                    <div style={{color:'#00a6e7'}}className='phones-contact-text-2'>
                      <b>HORARIO</b><br/>
                      Lunes a viernes 9 am - 8 pm <br/>
                      Sábados 9 am - 2 pm
                    </div>
                  </Col>
                  <Col md={ 7 }>
                    <div>
                      <div className="clearfixe-body">
                      </div>
                      <iframe title="Ubicación Anzures" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.6111947061551!2d-99.18321737152132!3d19.43638116353808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8ac24134035%3A0x987b7403c3b2f3a6!2sGauss%2012%2C%20Anzures%2C%20Miguel%20Hidalgo%2C%2011590%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1686447146971!5m2!1ses-419!2smx"
                        width={ isMobile ? '100%' : "600" } height={ isMobile ? '250' : "450" } allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>    
        </Container>
        </div>
        
        { !isMobile && <div className="clearfixe-body"></div> }

        <div>
          <Container>
            <Row>
              <Col md={6}>
                <div className='text-gust-2' style={{ color: '#364363' }}>Luce tu sonrisa</div>
                <div className='equipo-trabajo-main' style={{ textAlign: "left", padding: 0 }}>¡Contáctanos!</div>
                <br/>

                <Container>
                  <Form id="contactForm" className="contactForm" onSubmit={handleOnClick}>
                    <Form.Group>
                      <Form.Label className="label-custom">Nombre completo</Form.Label>
                      <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="label-custom">Correo Electrónico</Form.Label>
                      <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="label-custom">Teléfono</Form.Label>
                      <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="label-custom">Mensaje</Form.Label>
                      <Form.Control required name="comments" as="textarea" value={comments} rows="2" onChange={ (e) => setComments(e.target.value) } />
                    </Form.Group>

                    <div align="left">
                      <br/>
                      <Button variant="outline-primary" disabled={ isLoading } type="submit">
                        <span className='form-label'>{isLoading ? 'ENVIANDO…' : 'ENVIAR'}</span>&nbsp;
                      </Button>
                    </div>
                  </Form>
                </Container>
              </Col>

              <Col md={6}>
                { isMobile && <div className="clearfixe-body"></div> }
                <div className='contact-back'>
                  <img loading="lazy"  alt="" src={ receop } className='img-100 contact-img' />
                </div>
              </Col>
            </Row>
          </Container>
        </div> 
        <div className="clearfixe-body"></div>
        </>
    );

}

export default Contacto;